import React from "react";
import classnames from "classnames";
import { VerticalDotsIcon } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next/";
import { useSnapshot } from "valtio";

import store from "./store";

import PrinterSvgIcon from "@components/Common/SvgIcons/PrinterSvgIcon";

export function ActionColumn({ e, typeDisplay }: any) {
    const { t } = useTranslation();

    const { modify, isPrinterConfigOpened } = useSnapshot(store);

    return (
        <React.Fragment>
            {e.selectedRow.subRows === undefined && modify === true ? (
                <div
                    className={`${classnames(
                        "w-100 d-flex justify-content-center",
                        {
                            "not-allowed-icon__clz": isPrinterConfigOpened,
                        }
                    )}`}
                >
                    <div className="dropdown">
                        <VerticalDotsIcon
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            style={{ cursor: "pointer" }}
                            height={25}
                            width={25}
                            fill="black"
                            className={`${classnames("cmn_drp_FRmenu", {
                                "not-allowed-icon__clz":
                                    typeDisplay !== "notArchived",
                            })}`}
                        />
                        <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                        >
                            <div className="dropdown-item pl-1 afa_drp_importSettings not-allowed-icon__clz">
                                <PrinterSvgIcon height={25} width={25} />
                                <span
                                    onClick={() => {
                                        // if (isEdited === true) {
                                        //     setIsActionsClicked(
                                        //         !isActionsClicked
                                        //     );
                                        //     setActions(() => {
                                        //         setIsEdited(false);
                                        //         setIsAddPrinterClicked(false);
                                        //         if (
                                        //             isUpdateOperation ===
                                        //                 true &&
                                        //             isPrinterConfigOpened ===
                                        //                 true
                                        //         ) {
                                        //             setIsConfirmationEditRunning(
                                        //                 true
                                        //             );
                                        //         } else {
                                        //             setShopId(
                                        //                 e.selectedRow.original
                                        //                     .shopId
                                        //             );
                                        //             handleTestPrinterButtonOnClickEvent(
                                        //                 e.selectedRow.original
                                        //                     .ip,
                                        //                 e.selectedRow.original
                                        //                     .port
                                        //             );
                                        //         }
                                        //     });
                                        // } else {
                                        //     if (
                                        //         isUpdateOperation === true &&
                                        //         isPrinterConfigOpened === true
                                        //     ) {
                                        //         setIsConfirmationEditRunning(
                                        //             true
                                        //         );
                                        //     } else {
                                        //         setShopId(
                                        //             e.selectedRow.original
                                        //                 .shopId
                                        //         );
                                        //         handleTestPrinterButtonOnClickEvent(
                                        //             e.selectedRow.original.ip,
                                        //             e.selectedRow.original.port
                                        //         );
                                        //     }
                                        // }
                                    }}
                                    className="ml-2"
                                >
                                    {t("Test the printer")}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </React.Fragment>
    );
}
