import React from "react";
import { useTranslation } from "react-i18next";
import { CheckboxIcon, StyledH2, StyledLabel } from "@aureskonnect/react-ui";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import classnames from "classnames";
import { mutate } from "swr";
import { toast } from "react-toastify";
import { uuid } from "uuidv4";
import { useSnapshot } from "valtio";

import { store } from "@components/VerticalLayout/store";
import {
    clearData,
    setSelectedData,
} from "@components/Common/MultiCustomCreatableSelection/store";

import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import {
    CustomCardBody,
    CustomCardFooter,
} from "@components/Common/CustomCardStyled";
import MultiCustomCreatableSelection from "@components/Common/MultiCustomCreatableSelection";
import { getTypedTag } from "../SaleSupport/SaleSupportFranchise/helper";

import "react-toastify/dist/ReactToastify.css";
import "@assets/swal.css";
import "@assets/theme.css";

type ModalTagType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    rowData: any;
    setDataIsUpdated: Function;
    dataIsUpdated: number | boolean;
    tagList: any;
};
export default function ModalTag({
    isModalOpened,
    setIsModalOpened,
    rowData,
    setDataIsUpdated,
    dataIsUpdated,
    tagList,
}: ModalTagType) {
    const { t } = useTranslation();
    const { franchiseID } = useSnapshot(store);
    const [tags, setTags] = React.useState<any[]>([]);
    const [tagsData, setTagsData] = React.useState<any>([]);

    function areArraysEqual(arr1: any, arr2: any) {
        if (arr1.length !== arr2.length) return false;
        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i] !== arr2[i]) return false;
        }
        return true;
    }

    async function handleValidate() {
        if (areArraysEqual(tagList, tagsData)) {
            setIsModalOpened(false);
        } else {
            let tags: any = [];
            const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/typed_tag`;
            tagsData.forEach(async (el: any) => {
                tags.push({
                    id: uuid(),
                    designation: el,
                });
            });
            let data = {
                shopId: ["0", rowData.selectedRow.uid],
                franchiseId: franchiseID,
                data: tags,
            };
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(data),
            };
            try {
                mutate(
                    apiUrl,
                    await fetch(apiUrl, requestOptions)
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("error");
                            } else {
                                toast.success(
                                    `${t(
                                        "The modification has been successfully completed"
                                    )}`,
                                    {
                                        position: toast.POSITION.TOP_CENTER,
                                        theme: "colored",
                                        icon: (
                                            <CheckboxIcon
                                                height={25}
                                                width={25}
                                                fill="white"
                                            />
                                        ),
                                    }
                                );
                                setIsModalOpened(false);
                                setDataIsUpdated(!dataIsUpdated);
                            }
                        })
                );
            } catch (e) {}
        }
    }

    const formatCreateLabelTag = (inputValue: any) => (
        <span>
            {inputValue} ({t("New tag")})
        </span>
    );

    React.useEffect(() => {
        clearData();
        tagList.forEach((el: any) => {
            setSelectedData(el, "tag");
        });
        getTypedTag(0, franchiseID, setTags);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    React.useEffect(() => {
        setTagsData([...tagList]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    console.log({ tagsData, tagList });

    return (
        <AvForm>
            <Modal size="l" centered isOpen={isModalOpened}>
                <ModalHeader
                    className="text-capitalize"
                    toggle={() => {
                        setIsModalOpened(!isModalOpened);
                    }}
                >
                    <StyledH2 className="text-uppercase">
                        {t("Tag Setting")}
                    </StyledH2>
                </ModalHeader>
                <ModalBody>
                    <CustomCardBody>
                        <AvGroup>
                            <StyledLabel htmlFor="text" className="mt-3">
                                {t("Tag")}
                            </StyledLabel>
                            <MultiCustomCreatableSelection
                                setResult={setTagsData}
                                result={tagsData}
                                data={tags}
                                onChange={(e: any) => {}}
                                onCreateOption={(e: any) => {}}
                                placeholder={`${t("Select")}…`}
                                value={t("Choose")}
                                name="tag"
                                noOptionsMessage={() => t("No options")}
                                formatCreateLabel={formatCreateLabelTag}
                                className="sdv_inp_FRtags"
                                isSendingTag={false}
                            />
                            <div className="d-flex flex-wrap"></div>
                        </AvGroup>
                    </CustomCardBody>
                    <CustomCardFooter className="pt-1 pb-2">
                        <CustomSecondaryColorButton
                            outline
                            rounded
                            variant="light"
                            className="mr-2 afa_btn_FRcancel  cmn_btn_cancel"
                            onClick={() => {
                                setIsModalOpened(!isModalOpened);
                            }}
                        >
                            <span> {t("Cancel")}</span>
                        </CustomSecondaryColorButton>
                        <CustomMainColorButton
                            rounded
                            variant="primary"
                            onClick={() => {
                                handleValidate();
                            }}
                            className={classnames(
                                "afa_btn_FRvalidate cmn_btn_validate"
                            )}
                        >
                            {t("Validate")}
                        </CustomMainColorButton>
                    </CustomCardFooter>
                </ModalBody>
            </Modal>
        </AvForm>
    );
}
