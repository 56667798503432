import React from "react";
import { useTranslation } from "react-i18next";
import { StyledModal, StyledH2, StyledLabel } from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import { ConfirmationArchiveType } from "./Types";

export function ConfirmationArchive({ data }: ConfirmationArchiveType) {
    const { t } = useTranslation();

    return (
        <StyledModal
            toggle={true}
            isOpen={data.isArchiveSVModalOpened!}
            centered
        >
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    data.setIsArchiveSVModalOpened(
                        !data.isArchiveSVModalOpened
                    );
                }}
            >
                <StyledH2>{t("Alert")}</StyledH2>
            </ModalHeader>
            <ModalBody>
                <StyledLabel
                    className="pl-3 m-0"
                    style={{ whiteSpace: "pre-line" }}
                >
                    {t(
                        "Warning ! This third party may be used by other modules. Do you want to confirm the archiving of the selected third party ?"
                    )}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        data.setIsArchiveSVModalOpened(
                            !data.isArchiveSVModalOpened
                        );
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        data.setIsValidateClicked(true);
                        data.setIsArchiveSVModalOpened(
                            !data.isArchiveSVModalOpened
                        );
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
