import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useSnapshot } from "valtio";
import { ModalBody } from "reactstrap";

import { setDataGeneral } from "../store";
import {
    setTicketHeaderSaleModeBold,
    setTicketHeaderSaleModeBlackBackround,
    setTicketHeaderName_phone_number_bold,
    setTicketHeaderLogoCustomer,
    setTicketHeaderLogoKitchen,
    resetStore,
    StoreGeneral,
    setAutomaticPrintingNumberCustomer,
    setAutomaticPrintingNumberTickets,
    setPrinterInformationMode,
    setPrinterBold,
    setTicketHeaderStyleOrderNumber,
} from "./Hook/store";

import { PrinterFooter } from "../PrinterFooter";

import UserCard from "./Hook/UserCard";

import "../index.css";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";
import {
    setTicketHeaderPathCustomer,
    setTicketHeaderPathKitchen,
} from "./Hook/store";

type GeneralPanelSettingType = {
    rowData: any;
    oneShop: boolean;
};
export default function GeneralPanelSetting({
    rowData,
}: GeneralPanelSettingType) {
    const { t } = useTranslation();

    const {
        ticketHeaderSaleModeBlackBackround,
        NumberCustomer,
        NumberTicketsKitchen,
        ticketHeaderSaleModeBold,
        ticketHeaderName_phone_number_bold,
        ticketHeaderLogoCustomer,
        ticketHeaderLogoKitchen,
        printerBold,
        printerInformationMode,
        ticketHeaderPathCustomer,
        ticketHeaderPathKitchen,
        ticketHeaderStyleOrderNumber,
    } = useSnapshot(StoreGeneral);

    const [, setLogoCustomer] = React.useState<string>("");
    const [, setLogoKitchen] = React.useState<string>("");

    useEffect(() => {
        if (rowData["general"]?.ticket_header) {
            setTicketHeaderSaleModeBold(
                rowData["general"].ticket_header.sale_mode_bold
            );
            setTicketHeaderSaleModeBlackBackround(
                rowData["general"].ticket_header.sale_mode_black_backround
            );
            setTicketHeaderName_phone_number_bold(
                rowData["general"].ticket_header.name_phone_number_bold
            );
            setTicketHeaderStyleOrderNumber(
                rowData["general"].ticket_header.ticketHeaderStyleOrderNumber
            );
            setTicketHeaderPathKitchen(
                rowData["general"].ticket_header.Path_kitchen
            );
            setTicketHeaderLogoCustomer(
                rowData["general"].ticket_header.logo_customer
            );
            setTicketHeaderLogoKitchen(
                rowData["general"].ticket_header.logo_kitchen
            );
            setTicketHeaderPathCustomer(
                rowData["general"].ticket_header.Path_customer
            );
            setTicketHeaderPathKitchen(
                rowData["general"].ticket_header.Path_kitchen
            );
            setTicketHeaderStyleOrderNumber(
                rowData["general"].ticket_header.ticketHeaderStyleOrderNumber
            );
        }
        if (rowData["general"]?.automatic_printing?.NumberCustomer) {
            setAutomaticPrintingNumberCustomer(
                rowData["general"].automatic_printing.NumberCustomer
            );
        }
        if (rowData["general"]?.automatic_printing?.NumberTicketsKitchen) {
            setAutomaticPrintingNumberTickets(
                rowData["general"].automatic_printing.NumberTicketsKitchen
            );
        }
        if (rowData["general"]?.information_mode) {
            setPrinterInformationMode(
                rowData["general"].information_mode.printer_information_mode
            );
            setPrinterBold(
                rowData["general"].information_mode.information_bold
            );
        }
    }, [rowData]);

    // useEffect(() => {
    //     const shopId = oneShop ? shopID : rowData.shopId;
    //     // if (logoCustomer.length !== 0) {
    //     //     let picture = logoCustomer;
    //     //     let urlParams = "logoCustomer";
    //     //     let logo: any = InsertPicturesInServer({
    //     //         picture,
    //     //         franchiseID,
    //     //         shopId,
    //     //         urlParams,
    //     //     });

    //     //     setTicketHeaderPathCustomer(logo);
    //     // }
    //     // if (logoKitchen) {
    //     //     let urlParams = "logoKitchen";
    //     //     let picture = logoKitchen;
    //     //     let logo: any = InsertPicturesInServer({
    //     //         picture,
    //     //         franchiseID,
    //     //         shopId,
    //     //         urlParams,
    //     //     });

    //     //     setTicketHeaderPathKitchen(logo);
    //     // }
    // }, [
    //     franchiseID,
    //     logoCustomer,
    //     logoKitchen,
    //     shopID,
    //     oneShop,
    //     rowData.shopId,
    // ]);

    useEffect(() => {
        const data: any = {
            automatic_printing: {
                NumberCustomer,
                NumberTicketsKitchen,
            },
            ticket_header: {
                sale_mode_bold: ticketHeaderSaleModeBold,
                sale_mode_black_backround: ticketHeaderSaleModeBlackBackround,
                name_phone_number_bold: ticketHeaderName_phone_number_bold,
                logo_customer: ticketHeaderLogoCustomer,
                Path_customer: ticketHeaderPathCustomer,
                Path_kitchen: ticketHeaderPathKitchen,
                logo_kitchen: ticketHeaderLogoKitchen,
                ticketHeaderStyleOrderNumber: ticketHeaderStyleOrderNumber,
            },
            information_mode: {
                information_bold: printerBold,
                printer_information_mode: printerInformationMode,
            },
        };
        setDataGeneral(data);
    }, [
        printerBold,
        printerInformationMode,
        NumberCustomer,
        NumberTicketsKitchen,
        ticketHeaderSaleModeBold,
        ticketHeaderSaleModeBlackBackround,
        ticketHeaderLogoCustomer,
        ticketHeaderPathCustomer,
        ticketHeaderLogoKitchen,
        ticketHeaderPathKitchen,
        ticketHeaderName_phone_number_bold,
        ticketHeaderStyleOrderNumber,
    ]);

    return (
        <React.Fragment>
            <ModalBody style={{ height: "730px" }}>
                <div className="px-3 mt-2">
                    <h4 style={{ fontSize: "24px" }}>{t("General")}</h4>
                </div>
                <UserCard
                    setLogoCustomer={setLogoCustomer}
                    setLogoKitchen={setLogoKitchen}
                    ticketHeaderPathKitchen={ticketHeaderPathKitchen}
                    ticketHeaderPathCustomer={ticketHeaderPathCustomer}
                    rowData={rowData}
                />
            </ModalBody>
            <PrinterFooter rowData={rowData} resetStore={resetStore} />
        </React.Fragment>
    );
}
