import React from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { snapshot, useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { CheckboxIcon, CrossIcon, StyledButton } from "@aureskonnect/react-ui";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { diff } from "deep-diff";
import classnames from "classnames";
import moment from "moment";

import {
    computedStore,
    generalConfigStore,
    setChosenTemplate,
    setInitItemsFromGeneralSetting,
    setIsInformationModesEmpty,
    setIsModalOpened,
    setIsProjectModesModalOpened,
    setProject,
} from "@store";
import { store as useStore } from "@components/VerticalLayout/store";
import { store as mainColorStore } from "@store";
import {
    setIsLoading,
    setIsModalConfirmationConfigurationKiosk,
    setKioskProject,
    store,
} from "../../../store/project";
import { setKey, store as localStore } from "@pages/Project/store";

import { SwiperWrapper } from "../../FullscreenPreview/SwiperWrapper";
import { Modal as Modala } from "./Modal";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { moveArrayElementToTheBegin } from "../../../helpers/general";

import "./index.css";

type PropsType = {
    children: React.ReactNode;
    title: string;
    rowData?: any;
    setIsDataUpdated: Function;
    isDataUpdated: boolean | number;
    setIsCreatedProject: Function;
    setSelectedRows: Function;
};

export function ModalWrapper({
    children,
    title = "",
    rowData,
    setIsDataUpdated,
    isDataUpdated,
    setIsCreatedProject,
    setSelectedRows,
}: PropsType): JSX.Element {
    const { t } = useTranslation();
    const {
        userID,
        franchiseID,
        projectId,
        shopID,
        oneShop,
        operatorID,
    } = useSnapshot(useStore);
    const uuidUser = localStorage.getItem("uuidUser");
    const { initialProject } = useSnapshot(localStore);

    const {
        isModalOpened,
        isWaysTabActive,
        project,
        isFieldRequiredEmpty,
    } = useSnapshot(generalConfigStore);
    const { isConsult, dataProjectFranchise } = useSnapshot(store);
    const { items } = useSnapshot(computedStore);
    const [isModalShown, setModalVisibility] = React.useState(false);
    const [isDisabled, setIsDisabled] = React.useState(false);
    const { mainColor } = useSnapshot(mainColorStore);

    const toggle = () => {
        setIsModalOpened(!isModalOpened);
        setIsCreatedProject(false);
        setIsProjectModesModalOpened(false);
        setSelectedRows([]);
    };

    async function handleUpdateProjectButtonOnClickEvent() {
        let apiUrl: any;
        const kioskProject = JSON.parse(localStorage.getItem("project")!);
        setKioskProject(kioskProject);
        apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/project/template`;
        setIsLoading(true);
        mutate(
            apiUrl,
            await fetch(apiUrl, {
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    userId: userID,
                    franchiseId: franchiseID,
                    projectId:
                        Object.keys(rowData).length === 0
                            ? projectId
                            : rowData.projectId ?? rowData.id_project,
                    project_id: "PROJECT",
                    shopId:
                        Object.keys(rowData).length === 0
                            ? oneShop
                                ? shopID
                                : "0"
                            : rowData.shopId.toString(),
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                    modified_at: `${moment().format("DD/MM/YYYY [at] h:mm a")}`,
                    data: {
                        template: kioskProject.template,
                        files: kioskProject.files,
                    },
                }),
                method: "POST",
            })
                .then((response) => response.json())
                .then((result) => {
                    if (result.error) {
                        throw Error(result.message);
                    }
                    setIsModalOpened(!isModalOpened);
                    if (!oneShop) {
                        toast.success(`${t("Template saved successfully")}`, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        });

                        if (
                            rowData !== undefined &&
                            Object.keys(rowData).length !== 0 &&
                            rowData.template.id !== "" &&
                            rowData?.subRows.length > 0 &&
                            (diff(rowData.template, kioskProject.template) !==
                                undefined ||
                                diff(rowData.files, kioskProject.files) !==
                                    undefined)
                        ) {
                            setIsModalConfirmationConfigurationKiosk(true);
                        }
                    } else {
                        if (project.associates !== undefined) {
                            if (Object.keys(project.associates).length > 0) {
                                handleSaveTemplateOnClickEvent();
                            }
                        }
                    }
                    setIsLoading(false);
                    setKey(Math.floor(Math.random() * 10000));
                })

                .catch((error) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                })
        );
    }
    async function handleUpdateProjectShopsButtonOnClickEvent() {
        setIsLoading(true);
        let apiUrl: any;

        const kioskProject = JSON.parse(localStorage.getItem("project")!);
        setKioskProject(kioskProject);
        apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/project/template`;
        mutate(
            apiUrl,
            await fetch(apiUrl, {
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    userId: userID,
                    franchiseId: franchiseID,
                    project_id: "PROJECT",
                    projectId: kioskProject.projectId,
                    shopId: shopID,
                    operatorId: operatorID,
                    uuidUser: uuidUser,
                    modified_at: `${moment().format("DD/MM/YYYY [at] h:mm a")}`,
                    data: {
                        template: kioskProject.template,
                        files: kioskProject.files,
                    },
                }),
                method: "POST",
            })
                .then((response) => response.json())
                .then((result) => {
                    if (result.error) {
                        throw Error(result.message);
                    }

                    toast.success(`${t("Template saved successfully")}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        icon: (
                            <CheckboxIcon height={25} width={25} fill="white" />
                        ),
                    });

                    if (
                        rowData !== undefined &&
                        Object.keys(rowData).length > 0 &&
                        rowData.template.id !== "" &&
                        rowData?.subRows.length > 0 &&
                        (diff(rowData.template, kioskProject.template) !==
                            undefined ||
                            diff(rowData.files, kioskProject.files) !==
                                undefined)
                    ) {
                        setIsModalConfirmationConfigurationKiosk(true);
                    }
                    setIsLoading(false);
                    setKey(Math.floor(Math.random() * 10000));
                })
                .catch((error) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                })
        );
    }

    const closeModal = () => {
        setModalVisibility(false);
    };
    const handleSaveTemplateOnClickEvent = async () => {
        for (let row of Object.keys(project.associates)) {
            let template: any;
            const getLanguagesUrl: Response = await fetch(
                `${process.env.REACT_APP_API_V2_URL}/settings/consomation/langue?franchiseId=${franchiseID}&shopId=${row}`
            );
            const languages = await getLanguagesUrl.json();
            const getSalesMethodUrl: Response = await fetch(
                `${process.env.REACT_APP_API_V2_URL}/settings/consomation/sale?franchiseId=${franchiseID}&shopId=${row}`
            );
            const saleMethods = await getSalesMethodUrl.json();

            if (
                saleMethods.filter(
                    (item: any) =>
                        item.active === true &&
                        item.id !== "f25e7c96-b5d3-4f2e-acd0-1500258283c2"
                ).length === 0 ||
                languages.filter((item: any) => item.active === true).length ===
                    0
            ) {
                toast.warning(
                    `${t(
                        `Please note that the assignment could not be made to one or more stores. Please check the store(s) data settings.`
                    )!}`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        icon: (
                            <CheckboxIcon height={25} width={25} fill="white" />
                        ),
                    }
                );
                return;
            } else {
                const getReglementUrl: Response = await fetch(
                    `${process.env.REACT_APP_API_V2_URL}/settings/consomation/reglement?franchiseId=${franchiseID}&shopId=${row}`
                );
                const reglements = await getReglementUrl.json();

                const getInformationMethodUrl: Response = await fetch(
                    `${process.env.REACT_APP_API_V2_URL}/settings/consomation/information?franchiseId=${franchiseID}&shopId=${row}`
                );

                const informationModes = await getInformationMethodUrl.json();

                if (
                    informationModes.length === 0 ||
                    informationModes.filter(
                        // eslint-disable-next-line no-loop-func
                        (item: ProjectMainContentItemType) => {
                            return saleMethods.filter((saleMethod: any) => {
                                return (
                                    Object.keys(item.active).includes(
                                        saleMethod.name
                                    ) && saleMethod.active === true
                                );
                            });
                        }
                    ).length === 0
                ) {
                    setIsInformationModesEmpty(true);
                } else {
                    setIsInformationModesEmpty(false);
                }
                template = {
                    ...project.template,
                    content: {
                        ...project.template.content,
                        languages: {
                            ...project.template.content.languages,
                            items:
                                languages.filter(
                                    // eslint-disable-next-line no-loop-func
                                    (language: LanguagesItemType) =>
                                        language.active === true &&
                                        language.isDefault === false
                                ).length > 1
                                    ? moveArrayElementToTheBegin(
                                          languages.find(
                                              // eslint-disable-next-line no-loop-func
                                              (language: LanguagesItemType) =>
                                                  language.isDefault === true
                                          ),
                                          moveArrayElementToTheBegin(
                                              languages.find(
                                                  // eslint-disable-next-line no-loop-func
                                                  (
                                                      language: LanguagesItemType
                                                  ) =>
                                                      language.active ===
                                                          true &&
                                                      language.isDefault ===
                                                          false
                                              ),
                                              languages
                                          )
                                      )
                                    : languages,
                        },
                        salesMethods: {
                            items: saleMethods,
                        },
                        meansOfPayment: {
                            items: reglements,
                        },
                        informationModes: {
                            items: informationModes,
                        },
                    },
                };

                let files = project.files;
                setProject({
                    ...project,
                    template,
                    files,
                });
                setInitItemsFromGeneralSetting({
                    informationModes,
                    saleMethods,
                    reglements,
                    languages,
                });

                setChosenTemplate(template, files);
                let project1 = {
                    ...project,
                    template,
                };

                let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/project/template`;
                mutate(
                    apiUrl,
                    await fetch(apiUrl, {
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            userId: userID,
                            franchiseId: franchiseID,
                            projectId: project1.projectId,
                            project_id: "PROJECT",
                            shopId: row,
                            data: project1,
                            operatorId: operatorID,
                            uuidUser: uuidUser,
                        }),
                        method: "POST",
                    })
                        .then((response) => response.json())
                        .then((result) => {
                            if (result.error) {
                                throw Error(result.message);
                            }
                            setIsModalOpened(!isModalOpened);
                        })

                        .catch((error) => {
                            toast.error(`${t("There's an error")!}`, {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 2000,
                                theme: "colored",
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                            });
                        })
                );
            }
        }
    };

    const handleSaveTemplateFromShopsToFranchiseOnClickEvent = async (
        shops: any
    ) => {
        let isEmpty = false;
        let isSuccess = false;
        setIsLoading(true);
        for (let shopId of shops) {
            const kioskProject = JSON.parse(localStorage.getItem("project")!);
            let project1: any;
            let template: any;
            if (shopId === "0") {
                const getLanguagesUrl: Response = await fetch(
                    `${process.env.REACT_APP_API_V2_URL}/settings/consomation/langue?franchiseId=${franchiseID}&shopId=${shopId}`
                );
                const languages = await getLanguagesUrl.json();
                const getSalesMethodUrl: Response = await fetch(
                    `${process.env.REACT_APP_API_V2_URL}/settings/consomation/sale?franchiseId=${franchiseID}&shopId=${shopId}`
                );
                const saleMethods = await getSalesMethodUrl.json();

                if (
                    saleMethods.filter(
                        (item: any) =>
                            item.active === true &&
                            item.id !== "f25e7c96-b5d3-4f2e-acd0-1500258283c2"
                    ).length === 0 ||
                    languages.filter((item: any) => item.active === true)
                        .length === 0
                ) {
                    isEmpty = true;
                    return;
                } else {
                    const getReglementUrl: Response = await fetch(
                        `${process.env.REACT_APP_API_V2_URL}/settings/consomation/reglement?franchiseId=${franchiseID}&shopId=${shopId}`
                    );
                    const reglements = await getReglementUrl.json();

                    const getInformationMethodUrl: Response = await fetch(
                        `${process.env.REACT_APP_API_V2_URL}/settings/consomation/information?franchiseId=${franchiseID}&shopId=${shopId}`
                    );

                    const getLogoUrl: Response = await fetch(
                        `${process.env.REACT_APP_API_V2_URL}/settings/consomation/logo?franchiseId=${franchiseID}&shopId=${shopId}`
                    );

                    const informationModes = await getInformationMethodUrl.json();

                    const logo = await getLogoUrl.json();

                    if (
                        informationModes.length === 0 ||
                        informationModes.filter(
                            // eslint-disable-next-line no-loop-func
                            (item: ProjectMainContentItemType) => {
                                return saleMethods.filter((saleMethod: any) => {
                                    return (
                                        Object.keys(item.active).includes(
                                            saleMethod.name
                                        ) && saleMethod.active === true
                                    );
                                });
                            }
                        ).length === 0
                    ) {
                        setIsInformationModesEmpty(true);
                    } else {
                        setIsInformationModesEmpty(false);
                    }
                    template = {
                        ...project.template,
                        content: {
                            ...project.template.content,
                            languages: {
                                ...project.template.content.languages,
                                items:
                                    languages.filter(
                                        // eslint-disable-next-line no-loop-func
                                        (language: LanguagesItemType) =>
                                            language.active === true &&
                                            language.isDefault === false
                                    ).length > 1
                                        ? moveArrayElementToTheBegin(
                                              languages.find(
                                                  // eslint-disable-next-line no-loop-func
                                                  (
                                                      language: LanguagesItemType
                                                  ) =>
                                                      language.isDefault ===
                                                      true
                                              ),

                                              moveArrayElementToTheBegin(
                                                  languages.find(
                                                      // eslint-disable-next-line no-loop-func
                                                      (
                                                          language: LanguagesItemType
                                                      ) =>
                                                          language.active ===
                                                              true &&
                                                          language.isDefault ===
                                                              false
                                                  ),
                                                  languages
                                              )
                                          )
                                        : languages,
                            },
                            salesMethods: {
                                items: saleMethods,
                            },
                            meansOfPayment: {
                                items: reglements,
                            },
                            informationModes: {
                                items: informationModes,
                            },
                            generalDesign: {
                                ...project.template.content.generalDesign,
                                logo,
                            },
                        },
                    };

                    let filesTemplate = [...project.files];
                    // eslint-disable-next-line no-loop-func
                    saleMethods.map((item: ProjectMainContentItemType) => {
                        const data = {
                            id: item.id,
                            name: item.name,
                            content: Object.fromEntries(
                                Object.entries(item.languages).map(
                                    ([key, value]) => [
                                        key,
                                        {
                                            path: value.content as string,
                                            defaultImage: value.content,
                                        },
                                    ]
                                )
                            ),
                        };
                        return (filesTemplate = [...filesTemplate, data]);
                    });
                    // eslint-disable-next-line no-loop-func
                    informationModes.map((item: ProjectMainContentItemType) => {
                        const data = {
                            id: item.id,
                            name: item.name,
                            content: Object.fromEntries(
                                Object.entries(item.languages).map(
                                    ([key, value]) => [
                                        key,
                                        {
                                            path: value.content as string,
                                            defaultImage: value.content,
                                        },
                                    ]
                                )
                            ),
                        };
                        return (filesTemplate = [...filesTemplate, data]);
                    });
                    // eslint-disable-next-line no-loop-func
                    languages.map((item: LanguagesItemType) => {
                        const data = {
                            id: item.id,
                            name: item.name,
                            content: item.flag,
                            defaultImage: item.flag,
                        };

                        for (let file of filesTemplate) {
                            if (file.content !== undefined) {
                                if (typeof file.content === "object") {
                                    // eslint-disable-next-line no-prototype-builtins
                                    if (
                                        file.content.hasOwnProperty(
                                            item.name
                                        ) === false
                                    ) {
                                        const index = filesTemplate.indexOf(
                                            file
                                        );
                                        if (index > -1) {
                                            filesTemplate[index] = {
                                                ...filesTemplate[index],
                                                content: {
                                                    ...file.content,
                                                    [item.name]:
                                                        file.content.fr,
                                                },
                                            };
                                        }
                                    }
                                }
                            }
                        }

                        return (filesTemplate = [...filesTemplate, data]);
                    });

                    reglements

                        .filter(
                            // eslint-disable-next-line no-loop-func
                            (item: ProjectMainContentItemType) =>
                                item.name !== "At the counter" &&
                                item.name !== "BDP"
                        )
                        // eslint-disable-next-line no-loop-func
                        .map((item: ProjectMainContentItemType) => {
                            const data = {
                                id: item.id,
                                name: item.name,
                                content: Object.fromEntries(
                                    Object.entries(item.languages).map(
                                        ([key, value]) => [
                                            key,
                                            {
                                                path: value.content as string,
                                                defaultImage: value.content,
                                            },
                                        ]
                                    )
                                ),
                            };
                            return (filesTemplate = [...filesTemplate, data]);
                        });
                    const dataLogo = {
                        id: logo.id,
                        key: "Logo",
                        name: logo.name,
                        content: logo.content,
                        defaultImage: logo.content,
                    };

                    filesTemplate = [...filesTemplate, dataLogo];

                    setProject({
                        ...project,
                        template,
                        files: filesTemplate,
                    });
                    setInitItemsFromGeneralSetting({
                        informationModes,
                        saleMethods,
                        reglements,
                        languages,
                        logo,
                    });

                    setChosenTemplate(template, filesTemplate);
                    project1 = {
                        ...project,
                        template,
                        files: filesTemplate,
                    };
                }
            }
            let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/project/template`;
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        userId: userID,
                        franchiseId: franchiseID,
                        projectId:
                            Object.keys(rowData).length === 0
                                ? projectId
                                : shopId === "0"
                                ? project1.projectId
                                : kioskProject.projectId,
                        project_id: "PROJECT",
                        shopId: shopId,
                        operatorId: operatorID,
                        uuidUser: uuidUser,
                        modified_at: `${moment().format(
                            "DD/MM/YYYY [at] h:mm a"
                        )}`,
                        data: {
                            template:
                                shopId === "0"
                                    ? project1.template
                                    : kioskProject.template,
                            files:
                                shopId === "0"
                                    ? project1.files
                                    : kioskProject.files,
                        },
                    }),
                    method: "POST",
                })
                    .then((response) => response.json())
                    // eslint-disable-next-line no-loop-func
                    .then((result) => {
                        if (result.error) {
                            throw Error(result.message);
                        }
                        if (
                            rowData !== undefined &&
                            Object.keys(rowData).length !== 0 &&
                            rowData.template.id !== "" &&
                            rowData?.subRows.length > 0 &&
                            (diff(rowData.template, kioskProject.template) !==
                                undefined ||
                                diff(rowData.files, kioskProject.files) !==
                                    undefined)
                        ) {
                            setIsModalConfirmationConfigurationKiosk(true);
                        }
                        isSuccess = true;
                    })

                    .catch((error) => {
                        toast.error(`${t("There's an error")!}`, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    })
            );
        }
        setIsLoading(false);
        if (isEmpty)
            toast.warning(
                `${t(
                    `Please note that the assignment could not be made to one or more stores. Please check the store(s) data settings.`
                )!}`,
                {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    icon: <CheckboxIcon height={25} width={25} fill="white" />,
                }
            );
        if (isSuccess)
            toast.success(`${t("Template saved successfully")}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                icon: <CheckboxIcon height={25} width={25} fill="white" />,
            });
    };

    async function getDataFromGeneralSetting(
        shopId: string,
        templateProject: any
    ) {
        let template: any;
        const getLanguagesUrl: Response = await fetch(
            `${process.env.REACT_APP_API_V2_URL}/settings/consomation/langue?franchiseId=${franchiseID}&shopId=${shopId}`
        );
        const languages = await getLanguagesUrl.json();
        const getSalesMethodUrl: Response = await fetch(
            `${process.env.REACT_APP_API_V2_URL}/settings/consomation/sale?franchiseId=${franchiseID}&shopId=${shopId}`
        );
        const saleMethods = await getSalesMethodUrl.json();
        if (
            saleMethods.filter(
                (item: any) =>
                    item.active === true &&
                    item.id !== "f25e7c96-b5d3-4f2e-acd0-1500258283c2"
            ).length === 0 ||
            languages.filter((item: any) => item.active === true).length === 0
        ) {
            return;
        } else {
            const getReglementUrl: Response = await fetch(
                `${process.env.REACT_APP_API_V2_URL}/settings/consomation/reglement?franchiseId=${franchiseID}&shopId=${shopId}`
            );
            const reglements = await getReglementUrl.json();

            const getInformationMethodUrl: Response = await fetch(
                `${process.env.REACT_APP_API_V2_URL}/settings/consomation/information?franchiseId=${franchiseID}&shopId=${shopId}`
            );

            const informationModes = await getInformationMethodUrl.json();

            JSON.parse(
                JSON.stringify(templateProject.content.salesMethods)
            ).items.forEach((element: any) => {
                saleMethods.forEach((el: any) => {
                    if (el.id === element.id) {
                        el.active = element.active;
                        el.languages = element.languages;
                    }
                });
            });
            JSON.parse(
                JSON.stringify(templateProject.content.languages)
            ).items.forEach((element: any) => {
                languages.forEach((el: any) => {
                    if (el.id === element.id) {
                        el.active = element.active;
                    }
                });
            });

            JSON.parse(
                JSON.stringify(templateProject.content.informationModes)
            ).items.forEach((element: any) => {
                informationModes.forEach((el: any) => {
                    if (el.id === element.id) {
                        el.active = element.active;
                        el.languages = element.languages;
                    }
                });
            });
            JSON.parse(
                JSON.stringify(templateProject.content.meansOfPayment)
            ).items.forEach((element: any) => {
                reglements.forEach((el: any) => {
                    if (el.id === element.id) {
                        el.active = element.active;
                        el.languages = element.languages;
                    }
                });
            });
            if (
                informationModes.length === 0 ||
                informationModes.filter((item: any) => {
                    return saleMethods.filter((saleMethod: any) => {
                        return (
                            Object.keys(item.active).includes(
                                saleMethod.name
                            ) && saleMethod.active === true
                        );
                    });
                }).length === 0
            ) {
                setIsInformationModesEmpty(true);
            } else {
                setIsInformationModesEmpty(false);
            }

            template = {
                ...templateProject,
                content: {
                    ...templateProject.content,
                    languages: {
                        ...templateProject.content.languages,
                        items:
                            languages.filter(
                                (language: LanguagesItemType) =>
                                    language.active === true &&
                                    language.isDefault === false
                            ).length > 1
                                ? moveArrayElementToTheBegin(
                                      languages.find(
                                          (language: LanguagesItemType) =>
                                              language.isDefault === true
                                      ),
                                      moveArrayElementToTheBegin(
                                          languages.find(
                                              (language: LanguagesItemType) =>
                                                  language.active === true &&
                                                  language.isDefault === false
                                          ),
                                          languages
                                      )
                                  )
                                : languages,
                    },
                    salesMethods: {
                        items: saleMethods,
                    },
                    meansOfPayment: {
                        items: reglements,
                    },
                    informationModes: {
                        items: informationModes,
                    },
                },
            };
            return template;
        }
    }
    async function handleValidate() {
        setIsLoading(true);
        let localData: any = [];
        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/project/template`;
        for (let el of rowData.subRows) {
            let shopProject: any = await getDataFromGeneralSetting(
                el.id_boutique,
                project.template
            );
            let savedData: any = {
                userId: userID,
                franchiseId: franchiseID,
                projectId: project.projectId,
                project_id: "PROJECT",
                shopId: el.id_boutique,
                operatorId: operatorID,
                uuidUser: uuidUser,
                modified_at: `${moment().format("DD/MM/YYYY [at] h:mm a")}`,
                data: {
                    template: shopProject,
                    files: project.files,
                },
            };
            localData.push(
                fetch(apiUrl, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        authorization: `Bareer ${localStorage.getItem("jwt")}`,
                    },
                    body: JSON.stringify(savedData),
                }).then((response) => response.json())
            );
        }

        handleSaveProjectsButtonOnClickEvent(localData, apiUrl);
    }

    async function handleSaveProjectsButtonOnClickEvent(
        localData: any,
        apiUrl: any
    ) {
        try {
            mutate(
                apiUrl,
                await Promise.all(localData).then(async (result: any) => {
                    let errors: boolean[] = result.map((el: any) => el.error);
                    if (!errors.includes(true)) {
                        setIsLoading(false);
                    }
                })
            );
        } catch (e: any) {
            toast.error(`${t("There's an error")}!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    const handleUpdateTemplateFromShopToFranchiseOnClickEvent = async () => {
        try {
            const kioskProject = JSON.parse(localStorage.getItem("project")!);
            const apiUrl = `${
                process.env.REACT_APP_API_V2_URL
            }/settings/projectData?userId=106&shopId=${"0"}&franchiseId=${franchiseID}&projectId=${projectId}`;

            const response = await fetch(apiUrl, {
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bearer ${localStorage.getItem("jwt")}`,
                },
                method: "GET",
            });
            const data = await response.json();
            let dataProject = JSON.parse(JSON.stringify(data[0]));

            let modifiedDataProject = JSON.parse(JSON.stringify(dataProject));

            const differences = processDifferences(dataProject, kioskProject);

            let { modifiedDataProject: updatedData, warn } = processUpdatedData(
                modifiedDataProject,
                differences,
                "0",
                false,
                kioskProject
            );

            let apiUrl1 = `${process.env.REACT_APP_API_V2_URL}/settings/project/template`;
            mutate(
                apiUrl1,
                await fetch(apiUrl1, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        userId: userID,
                        franchiseId: franchiseID,
                        projectId: updatedData.projectId,

                        project_id: "PROJECT",
                        shopId: "0",
                        operatorId: operatorID,
                        uuidUser: uuidUser,
                        modified_at: `${moment().format(
                            "DD/MM/YYYY [at] h:mm a"
                        )}`,
                        data: {
                            template: updatedData.template,
                            files: updatedData.files,
                        },
                    }),
                    method: "POST",
                })
                    .then((response) => response.json())
                    // eslint-disable-next-line no-loop-func
                    .then((result) => {
                        if (result.error) {
                            throw Error(result.message);
                        }

                        toast.success(
                            `${t("Template franchise saved successfully")}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 2000,
                                theme: "colored",
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setIsLoading(false);
                    })

                    .catch((error) => {
                        toast.error(`${t("There's an error")!}`, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    })
            );
        } catch (error) {
            console.log(
                "There is an error in handleUpdateTemplateFromShopToFranchiseOnClickEvent",
                error
            );
        }
    };
    return (
        <React.Fragment>
            <Modal
                centered
                backdrop="static"
                fade={false}
                scrollable
                toggle={toggle}
                isOpen={isModalOpened}
                className="modal-wrapper__clz"
            >
                <ModalHeader
                    toggle={toggle}
                    className="text-uppercase cursor__clz"
                >
                    <span className="pme_txt_popUpTitle"> {title}</span>
                </ModalHeader>
                <ModalBody className="p-0" style={{ overflowY: "unset" }}>
                    {children}
                </ModalBody>
                <ModalFooter>
                    {isWaysTabActive ? (
                        <StyledButton
                            rounded
                            className="m-2"
                            outline
                            variant={mainColor}
                            disabled={Object.values(items.ways).length === 0}
                            onClick={() => setModalVisibility(true)}
                        >
                            {t("See")}
                        </StyledButton>
                    ) : null}
                    {isConsult ? (
                        <CustomMainColorButton
                            className="w-20"
                            rounded
                            variant="primary"
                            onClick={() => {
                                setIsModalOpened(false);
                                setIsProjectModesModalOpened(false);
                                setSelectedRows([]);
                            }}
                        >
                            {t("Close")}
                        </CustomMainColorButton>
                    ) : (
                        <CustomMainColorButton
                            disabled={
                                project.template.id === "" ||
                                isFieldRequiredEmpty === true ||
                                isDisabled
                                    ? true
                                    : false
                            }
                            className={`${classnames(
                                "w-20 cmn_btn_SaveButton",
                                {
                                    "not-allowed-icon__clz": isDisabled,
                                }
                            )}`}
                            rounded
                            variant="primary"
                            onClick={() => {
                                setIsDisabled(true);
                                const kioskProject = JSON.parse(
                                    localStorage.getItem("project")!
                                );
                                const hasTemplateOrFilesChanged =
                                    diff(
                                        initialProject.template,
                                        kioskProject.template
                                    ) !== undefined ||
                                    diff(
                                        initialProject.files,
                                        kioskProject.files
                                    ) !== undefined;
                                if (hasTemplateOrFilesChanged) {
                                    const isOneShop = oneShop;
                                    const isRowDataEmpty =
                                        rowData === undefined ||
                                        Object.keys(rowData).length === 0;

                                    const isRowDataFromShop =
                                        oneShop &&
                                        dataProjectFranchise !== undefined &&
                                        dataProjectFranchise.find(
                                            (el: any) =>
                                                el.projectId === projectId
                                        ).subRows.length === 1;

                                    const isRowDataTemplateIdEmpty =
                                        rowData?.template !== undefined &&
                                        rowData?.template.id === "";
                                    const franchiseData = dataProjectFranchise.find(
                                        (el) => el.projectId === projectId
                                    );

                                    if (isRowDataFromShop) {
                                        handleUpdateTemplateFromShopToFranchiseOnClickEvent();
                                    }
                                    if (isOneShop && isRowDataEmpty) {
                                        const shops = [
                                            ...franchiseData.subRows.map(
                                                (elt: any) => elt.shopId
                                            ),
                                            "0",
                                        ];
                                        handleSaveTemplateFromShopsToFranchiseOnClickEvent(
                                            shops
                                        );
                                    } else if (
                                        isOneShop &&
                                        !isRowDataEmpty &&
                                        !isRowDataTemplateIdEmpty
                                    ) {
                                        handleUpdateProjectShopsButtonOnClickEvent();
                                    } else if (
                                        !isOneShop &&
                                        !isRowDataEmpty &&
                                        isRowDataTemplateIdEmpty &&
                                        rowData.subRows.length !== 0
                                    ) {
                                        handleUpdateProjectButtonOnClickEvent();
                                        handleValidate();
                                    } else {
                                        handleUpdateProjectButtonOnClickEvent();
                                    }
                                }
                                setIsProjectModesModalOpened(false);
                                setIsModalOpened(false);
                                setIsCreatedProject(false);
                            }}
                        >
                            {t("Save")}
                        </CustomMainColorButton>
                    )}
                </ModalFooter>
            </Modal>
            {isModalShown && (
                <Modala close={closeModal}>
                    <div
                        style={{
                            height: "940px",
                            display: "grid",
                            gridTemplateRows: "0.001fr 0.999fr",
                            marginTop: "-185px",
                            marginLeft: "-280px",
                            borderRadius: "9px",
                            background:
                                "linear-gradient(227.9deg, #1F1726 0%, #120E16 100%)",
                            boxShadow:
                                "-5px -5px 11px #221A2B, 5px 5px 11px #16101C",
                            position: "fixed",
                            zIndex: 500000,
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                alignItems: "start",
                                justifyContent: "end",
                                padding: "10px",
                            }}
                        >
                            <CrossIcon
                                fill="white"
                                height={10}
                                width={10}
                                onClick={() => {
                                    closeModal();
                                }}
                            />
                        </div>

                        <SwiperWrapper />
                    </div>
                </Modala>
            )}
        </React.Fragment>
    );
}

const getDiff = (type: any, modified: any, initial: any) => {
    let newData: any = [];
    if (
        Object.keys(
            JSON.parse(JSON.stringify(modified.template.content[type] as any))
        ).includes("items")
    ) {
        (modified.template.content[type] as any).items.forEach((el: any) => {
            let element = (initial.template.content[type] as any).items.find(
                (elt: any) => elt.id === el.id
            );
            if (element !== undefined) {
                newData.push(JSON.parse(JSON.stringify(element)));
            }
        });
    }

    let difference: any =
        Object.keys(
            JSON.parse(JSON.stringify(modified.template.content[type] as any))
        ).includes("items") &&
        diff(
            JSON.parse(
                JSON.stringify((modified.template.content[type] as any).items)
            ),
            newData
        )
            ? diff(
                  newData,
                  JSON.parse(
                      JSON.stringify(
                          (modified.template.content[type] as any).items
                      )
                  )
              )
            : [];
    let newDiff = [...difference];
    if (difference.length !== 0) {
        newDiff = difference.map((element: any) => {
            let defaultArray = ["template", "content", `${type}`, "items"];
            if (element.path !== undefined) {
                element.path.forEach((item: any) => defaultArray.push(item));
            }
            return { ...element, path: defaultArray };
        });
    }

    return newDiff;
};

export const processDifferences = (initial: any, modified: any) => {
    let differences: any = diff(initial, modified);
    if (differences) {
        Object.keys(modified.template.content)
            .filter(
                (item: any) =>
                    item !== "TPAValina" &&
                    item !== "generalDesign" &&
                    item !== "finalMessage"
            )
            .forEach((el: any) => {
                const diff = getDiff(el, modified, initial);
                differences = differences.filter(
                    (elt: any) => elt.path[2] !== el || elt.path.includes("id")
                );
                differences = [...differences, ...diff];
            });
    }
    return differences;
};

export const processUpdatedData = (
    modifiedDataProject: any,
    differences: any,
    shopId: string,
    warn: boolean,
    modified: any
) => {
    let changedItems: any = [];
    differences.forEach((change: any) => {
        const { path } = change;
        const kind = change.item?.kind ?? change.kind;
        const rhs = change?.rhs ?? change?.item?.rhs ?? null;
        const deletedElement = change.item?.lhs ?? change.lhs ?? null;

        let data = applyChange(
            modifiedDataProject,
            path,
            kind,
            JSON.parse(JSON.stringify(rhs)),
            warn,
            changedItems,
            JSON.parse(JSON.stringify(deletedElement)),
            shopId,
            modified
        );

        modifiedDataProject = data.obj;
        warn = data.warn;
    });
    return { modifiedDataProject, warn };
};

export function applyChange(
    obj: any,
    path: any,
    kind: string,
    value: any,
    warn: boolean,
    changedItems: any,
    deletedElement: any,
    shopId: any,
    modified: any
) {
    const { itemsDisable } = snapshot(generalConfigStore);
    if (path.includes("standbyScreen") && path.includes("items")) {
        // eslint-disable-next-line no-eval
        eval(
            `(obj.template.pages.ways.standbyScreen.items =
                modified.template.pages.ways.standbyScreen.items)`
        );
    } else if (path.includes("standbyStateScreen")) {
        // eslint-disable-next-line no-eval
        eval(
            `(obj.template.content.standbyStateScreen.items =
                modified.template.content.standbyStateScreen.items)`
        );
    } else if (path.includes("files")) {
        if (value !== null) {
            const isNewObjectAddedToFiles =
                obj.files.findIndex((el: any) => el.id === value.id) === -1 &&
                obj.files[path[1]].id === undefined;
            const isObjectDeletedFromFiles =
                obj.files.findIndex((el: any) => el.id === value.id) === -1 &&
                obj.files[path[1]].id === undefined;
            if (isNewObjectAddedToFiles === true) {
                obj.files.push(value);
            } else if (isObjectDeletedFromFiles === true) {
                obj.files.filter((item: any) => item.id !== value.id);
            } else {
                let id = modified.files[path[1]].id;
                let index = obj.files.findIndex((el: any) => el.id === id);
                if (index !== -1) {
                    let bracketNotationString = path
                        .map((element: any, key: number) => {
                            if (key > 1) {
                                if (!isNaN(element)) {
                                    return `[${element}]`;
                                }
                                return `['${element}']`;
                            } else return "";
                        })
                        .join("");
                    // eslint-disable-next-line no-eval
                    eval(`obj.files[${index}]${bracketNotationString} = value`);
                }
            }
        }
    } else {
        let indexOfItems: number = path.findIndex((el: any) => el === "items");
        let itemOccurrence =
            indexOfItems !== -1
                ? path.filter((el: any) => el === "items").length
                : 0;
        if (indexOfItems !== -1) {
            if (path[1] === "content") {
                let indexOfId: number = path.findIndex(
                    (el: any) => el === "id"
                );
                let newP = [];
                for (let index = 0; index <= indexOfItems; index++) {
                    newP.push(path[index]);
                }

                if (
                    (indexOfId !== -1 &&
                        !changedItems.includes(path[indexOfItems - 1])) ||
                    itemOccurrence === 2
                ) {
                    changedItems.push(path[indexOfItems - 1]);
                    let bracketNotationString = newP
                        .map((element: any) => {
                            if (!isNaN(element)) {
                                return `[${element}]`;
                            }
                            return `['${element}']`;
                        })
                        .join("");

                    let items: any = [];
                    let itemsShop: any = [];
                    let newItems: any = [];
                    // eslint-disable-next-line no-eval
                    eval(`items=modified${bracketNotationString}`);
                    // eslint-disable-next-line no-eval
                    eval(`itemsShop=obj${bracketNotationString}`);

                    if (path[2] !== "languages") {
                        items.forEach((el: any) => {
                            let index: number = itemsShop.findIndex(
                                (elt: any) => el.id === elt.id
                            );
                            if (index !== -1) {
                                newItems.push({
                                    ...itemsShop[index],
                                });
                            }
                        });
                        // eslint-disable-next-line no-eval
                        eval(`obj${bracketNotationString} = newItems`);
                    } else {
                        let favoredFranchise: any = items.find(
                            (el: any) => el.isDefault === true
                        ).id;
                        let isFavoredExistInShop: any = itemsShop.find(
                            (el: any) => el.id === favoredFranchise
                        );
                        if (
                            isFavoredExistInShop !== undefined &&
                            itemsDisable.find(
                                (disabled: any) =>
                                    disabled.id === favoredFranchise &&
                                    disabled.shopId === shopId
                            ) === undefined
                        ) {
                            items.forEach((el: any) => {
                                let index: number = itemsShop.findIndex(
                                    (elt: any) => el.id === elt.id
                                );

                                let Shop = itemsShop[index];
                                const isItemEnabled = (
                                    item: any,
                                    shopId: number
                                ) => {
                                    const disabledItem = itemsDisable.find(
                                        (disabledItem: any) =>
                                            disabledItem.id === item.id &&
                                            disabledItem.shopId === shopId
                                    );
                                    return disabledItem;
                                };

                                let active =
                                    el.isDefault === true &&
                                    Shop.active === false &&
                                    isItemEnabled(el, shopId) === undefined
                                        ? true
                                        : Shop.active;

                                if (index !== -1) {
                                    newItems.push({
                                        ...itemsShop[index],
                                        isDefault: el.isDefault,
                                        active: active,
                                    });
                                }
                            });
                            // eslint-disable-next-line no-eval
                            eval(`obj${bracketNotationString} = newItems`);
                        } else {
                            warn = true;
                        }
                    }
                } else {
                    let x = [...newP, path[indexOfItems + 1]].reduce(
                        (obj1: any, key: any) => obj1[key],
                        modified
                    );
                    let id = "";
                    if (x !== undefined) {
                        id = x.id;
                    }
                    let indexInShop: any = [...newP]
                        .reduce((obj1: any, key: any) => obj1[key], obj)
                        .findIndex((el: any) => el.id === id);

                    if (
                        itemsDisable.some(
                            (el: any) => el.id === id && el.shopId === shopId
                        ) &&
                        value === true
                    ) {
                        warn = true;
                    } else if (indexInShop !== -1) {
                        path.splice(indexOfItems + 1, 1, indexInShop);
                        // eslint-disable-next-line
                        let bracketNotationString = path
                            .map((element: any) => {
                                if (!isNaN(element)) {
                                    return `[${element}]`;
                                }
                                return `['${element}']`;
                            })
                            .join("");

                        let isFavored =
                            obj?.template?.content?.languages?.items[
                                indexInShop
                            ]?.isDefault ?? false;

                        if (
                            !(
                                path[2] === "languages" &&
                                !path.includes("isDefault") &&
                                value === false &&
                                isFavored === true
                            )
                        ) {
                            kind === "D"
                                ? // eslint-disable-next-line no-eval
                                  eval(`delete obj${bracketNotationString}`)
                                : // eslint-disable-next-line no-eval
                                  eval(`obj${bracketNotationString} = value`);
                        } else if (
                            path[2] === "languages" &&
                            value === false &&
                            isFavored === true
                        ) {
                            warn = true;
                        }
                    }
                }
            }
            if (
                path.includes("ways") ||
                path.includes("elements") ||
                path.includes("subSteps")
            ) {
                let keys = Object.keys(obj.template.pages[path[2]]);
                let bracketNotationString = path
                    .map((element: any) => {
                        if (!isNaN(element)) {
                            return `[${element}]`;
                        }
                        return `['${element}']`;
                    })
                    .join("");
                if (keys.includes(path[3])) {
                    kind === "D"
                        ? // eslint-disable-next-line no-eval
                          eval(`delete obj${bracketNotationString}`)
                        : // eslint-disable-next-line no-eval
                          eval(`obj${bracketNotationString} = value`);
                } else if (kind === "N") {
                    // eslint-disable-next-line no-eval
                    eval(`obj${bracketNotationString} = value`);
                }
            }
        } else {
            if (
                path.includes("ways") ||
                path.includes("elements") ||
                path.includes("subSteps")
            ) {
                let keys = Object.keys(obj.template.pages[path[2]]);
                let bracketNotationString = path
                    .map((element: any) => {
                        if (!isNaN(element)) {
                            return `[${element}]`;
                        }
                        return `['${element}']`;
                    })
                    .join("");
                if (keys.includes(path[3])) {
                    kind === "D"
                        ? // eslint-disable-next-line no-eval
                          eval(`delete obj${bracketNotationString}`)
                        : // eslint-disable-next-line no-eval
                          eval(`obj${bracketNotationString} = value`);
                } else if (kind === "N") {
                    // eslint-disable-next-line no-eval
                    eval(`obj${bracketNotationString} = value`);
                }
            } else {
                let pathSetting = path
                    .map((element: any) => {
                        if (!isNaN(element)) {
                            return `[${element}]`;
                        }
                        return `['${element}']`;
                    })
                    .join("");
                // eslint-disable-next-line no-eval
                eval(`obj${pathSetting} =
                modified${pathSetting}`);
            }
        }
    }
    return { obj, warn };
}
